import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'registerstore',
    templateUrl: './registerstore.component.html',
})
export class RegisterStoreComponent implements OnInit {
    Form_AddUser_Processing = false;
    ShowCategorySelector: boolean = true;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
        public _ChangeDetectorRef: ChangeDetectorRef
    ) {

    }
    ngOnInit() {
        // this.GetBusinessCategories(); 
        this.Form_AddUser_Load();
        // this.GetBusinessCategories();
        // this.GetBusinessCategories();
        // this.GetBusinessCategories();
    }


    _CurrentAddress: any = {};
    Form_AddUser: FormGroup;
    Form_AddUser_Load() {
        this.Form_AddUser = this._FormBuilder.group({
            OperationType: 'new',
            Task: this._HelperService.AppConfig.Api.Core.onboardmerchant,
            AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
            Name: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
            // EmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            // MobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(14)])],
            WebsiteUrl: [null, Validators.compose([Validators.minLength(2), Validators.pattern(this._HelperService.AppConfig.ValidatorRegex.WebsiteUrl)])],
            Description: null,
            Address: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(256)])],
            StateName: null,
            CityName: null,
            CountryName: null,
            Latitude: null,
            Longitude: null,
            StatusCode: this._HelperService.AppConfig.Status.Active,
        });
    }
    Form_AddUser_Process(_FormValue: any) {

        if (this.Form_AddUser_Latitude == 0 || this.Form_AddUser_Longitude == 0) {
            this._HelperService.NotifyError('Mark your business location on map to continue');
        }
        else {

            var Request = this.CreateRequestJson();
            // _FormValue.DisplayName = _FormValue.Name;
            // _FormValue.UserName = this._HelperService.GeneratePassoword();
            // _FormValue.Password = this._HelperService.GeneratePassoword();
            this.Form_AddUser_Processing = true;
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, Request);
            _OResponse.subscribe(
                _Response => {
                    this.Form_AddUser_Processing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.MerchantReferenceKey = _Response.Result.ReferenceKey;
                        this._HelperService.MerchantReferenceId = _Response.Result.ReferenceId;
                        this._HelperService.MerchantToken = _Response.Result.Token;
                        //         Password: this._HelperService.OnBoardMerchant.Password
                        this._HelperService.SaveStorage(
                            this._HelperService.AppConfig.Storage.StoreRegistrationData,
                            {
                                ReferenceKey: _Response.Result.ReferenceKey,
                                ReferenceId: _Response.Result.ReferenceId,
                                MerchantToken: _Response.Result.Token,
                                Email: this._HelperService.OnBoardMerchant.EmailAddress,
                                Password: this._HelperService.OnBoardMerchant.Password
                            }
                        );






                        // window.location.href = this._HelperService.AppConfig.Pages.System.MerchantRewardSetup;
                        this._Router.navigate([
                            this._HelperService.AppConfig.Pages.System.MerchantRewardSetup,

                        ]);
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this.Form_AddUser_Processing = false;
                    this._HelperService.HandleException(_Error);
                });
        }
    }
    Form_AddUser_Address: string = null;
    Form_AddUser_Latitude: number = 0;
    Form_AddUser_Longitude: number = 0;
    Form_AddUser_PlaceMarkerClick(event) {
        this.Form_AddUser_Latitude = event.coords.lat;
        this.Form_AddUser_Longitude = event.coords.lng;
    }
    public Form_AddUser_AddressChange(address: Address) {
        // this.Form_AddUser_Latitude = address.geometry.location.lat();
        // this.Form_AddUser_Longitude = address.geometry.location.lng();
        // this.Form_AddUser_Address = address.formatted_address;

        this.Form_AddUser_Latitude = address.geometry.location.lat();
        this.Form_AddUser_Longitude = address.geometry.location.lng();

        this.Form_AddUser_Address = address.formatted_address;
        // this.Form_AddUser.controls['Address'].patchValue(address.formatted_address);
        this._CurrentAddress = this._HelperService.GoogleAddressArrayToJson(address.address_components);
        // this.Form_AddUser.controls['CityName'].patchValue(this._CurrentAddress.sublocality_level_2);
        // this.Form_AddUser.controls['StateName'].patchValue(this._CurrentAddress.sublocality_level_1);
        // this.Form_AddUser.controls['CountryName'].patchValue(this._CurrentAddress.country);
        this.Form_AddUser.controls['Latitude'].patchValue(this.Form_AddUser_Latitude);
        this.Form_AddUser.controls['Longitude'].patchValue(this.Form_AddUser_Longitude);

        if (this._CurrentAddress.country != this._HelperService.UserCountrycode) {
            this._HelperService.NotifyError('Currently we’re not serving in this area, please add locality within ' + this._HelperService.UserCountrycode);
            this.reset();

        }
        else {
            this.Form_AddUser.controls['Address'].setValue(address.formatted_address);
            this.Form_AddUser.controls['CityName'].patchValue(this._CurrentAddress.sublocality_level_2);
            this.Form_AddUser.controls['StateName'].patchValue(this._CurrentAddress.sublocality_level_1);
            this.Form_AddUser.controls['CountryName'].patchValue(this._CurrentAddress.country);
            //this.Form_AddUser.controls['MapAddress'].setValue(address.formatted_address);
        }
    }

    reset() {
        this.Form_AddUser.controls['Address'].reset()
        this.Form_AddUser.controls['CityName'].reset()
        this.Form_AddUser.controls['StateName'].reset()
        this.Form_AddUser.controls['CountryName'].reset()

    }


    CreateRequestJson(): void {
        var AccountDetail = this._HelperService.OnBoardMerchant
        AccountDetail.Name = this.Form_AddUser.controls['Name'].value,
            AccountDetail.AccountOperationTypeCode = this.Form_AddUser.controls['AccountOperationTypeCode'].value,
            AccountDetail.StatusCode = this.Form_AddUser.controls['StatusCode'].value,
            // AccountDetail.EmailAddress = this.Form_AddUser.controls['EmailAddress'].value,
            // AccountDetail.MobileNumber = this.Form_AddUser.controls['MobileNumber'].value,
            AccountDetail.Description = this.Form_AddUser.controls['Description'].value,
            AccountDetail.Name = this.Form_AddUser.controls['Name'].value,

            AccountDetail.Address = {
                Latitude: this.Form_AddUser_Latitude,
                Longitude: this.Form_AddUser_Longitude,
                Address: this.Form_AddUser.controls['Address'].value,
                // CityName: this._CurrentAddress.sublocality_level_2,
                // StateName: this._CurrentAddress.sublocality_level_1,
                CountryName: this._CurrentAddress.country
            };

        // if (this.SelectedBusinessCategories != undefined && this.SelectedBusinessCategories.length > 0) {
        //     AccountDetail.Categories = []
        //     this.SelectedBusinessCategories.forEach(element => {
        //         AccountDetail.Categories.push(
        //             {
        //                 ReferenceKey: element.key,
        //                 ReferenceId: element.id
        //             }
        //         )
        //     });
        // }

        return AccountDetail;
    }

    public OriginalBusinessCategories = [];
    public BusinessCategories = [];
    public MerchantBusinessCategories = [];
    GetBusinessCategories() {
        var PData =
        {
            Task: this._HelperService.AppConfig.Api.Core.GetCoreCommons,
            SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
            SortExpression: 'Name asc',
            Offset: 0,
            Limit: 1000,
        }
        PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
            PData.SearchCondition,
            "TypeCode",
            this._HelperService.AppConfig.DataType.Text,
            this._HelperService.AppConfig.HelperTypes.MerchantCategories,
            "="
        );
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, PData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    if (_Response.Result.Data != undefined) {
                        this.OriginalBusinessCategories = _Response.Result.Data;
                        this.GetMerchantBusinessCategories();
                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._HelperService.IsFormProcessing = false;
                this._HelperService.HandleException(_Error);
            });
    }
    GetMerchantBusinessCategories() {
        this.BusinessCategories = [];
        var PData =
        {
            Task: this._HelperService.AppConfig.Api.Core.GetUserParameters,
            SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
            SortExpression: 'Name asc',
            Offset: 0,
            Limit: 1000,
        }
        PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
            PData.SearchCondition,
            "TypeCode",
            this._HelperService.AppConfig.DataType.Text,
            this._HelperService.AppConfig.HelperTypes.MerchantCategories,
            "="
        );
        PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
            PData.SearchCondition,
            "UserAccountKey",
            this._HelperService.AppConfig.DataType.Text,
            this._HelperService.UserAccount.AccountKey,
            "="
        );
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, PData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    if (_Response.Result.Data != undefined) {
                        this.MerchantBusinessCategories = _Response.Result.Data;
                        this.OriginalBusinessCategories.forEach(element => {
                            var ElementIndexSearch = this.MerchantBusinessCategories.findIndex(x => x.CommonKey == element.ReferenceKey);
                            if (ElementIndexSearch == -1) {
                                this.BusinessCategories.push(element);
                            }
                        });
                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._HelperService.IsFormProcessing = false;
                this._HelperService.HandleException(_Error);
            });
    }
    SaveMerchantBusinessCategory(item) {
        if (item != '0') {
            var Setup =
            {
                Task: this._HelperService.AppConfig.Api.Core.SaveUserParameter,
                TypeCode: this._HelperService.AppConfig.HelperTypes.MerchantCategories,
                UserAccountKey: this._HelperService.UserAccount.AccountKey,
                CommonKey: item,
                StatusCode: 'default.active'
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, Setup);
            _OResponse.subscribe(
                _Response => {
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        // this._HelperService.NotifySuccess('Business category assigned to merchant');
                        this.BusinessCategories = [];
                        this.GetMerchantBusinessCategories();
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this._HelperService.IsFormProcessing = false;
                    this._HelperService.HandleException(_Error);
                });
        }
    }
    DeleteMerchantBusinessCategory(Item) {

        var PData =
        {
            Task: this._HelperService.AppConfig.Api.Core.DeleteUserParameter,
            ReferenceKey: Item.ReferenceKey
        }
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, PData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    // this._HelperService.NotifySuccess('Merchant business category removed successfully')
                    this.GetMerchantBusinessCategories();
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._HelperService.IsFormProcessing = false;
                this._HelperService.HandleException(_Error);
            });

    }

}
