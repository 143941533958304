import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as Feather from "feather-icons";
import swal from "sweetalert2";
declare var moment: any;
declare var $: any;

import {
  DataHelperService,
  HelperService,
  OList,
  OSelect,
  FilterHelperService,
  OResponse,
  OCoreCommon
} from "../../../service/service";
import { Subscription, Observable } from 'rxjs';
import { InputFile, InputFileComponent } from 'ngx-input-file';

@Component({
  selector: 'app-tusubcategories',
  templateUrl: './tusubcategories.component.html'
})
export class TusubcategoriesComponent implements OnInit, OnDestroy {
  public ResetFilterControls: boolean = true;
  public _ObjectSubscription: Subscription = null;
  rootCategoryId: any;
  categoryrefkey: any;
  categoryrefid: any;
  ReferenceId: any;
  ReferenceKey: any;
  RootCategoryId: any;
  imageChangedEvent: any;
  urls = new Array<string>();
  CategeoryResponse: any;
  constructor(
    public _Router: Router,
    public _ActivatedRoute: ActivatedRoute,
    public _FormBuilder: FormBuilder,
    public _HelperService: HelperService,
    public _DataHelperService: DataHelperService,
    public _ChangeDetectorRef: ChangeDetectorRef,
    public _FilterHelperService: FilterHelperService
  ) {
    this._HelperService.showAddNewPosBtn = false;
    this._HelperService.showAddNewStoreBtn = false;
    this._HelperService.showAddNewCashierBtn = true;
    this._HelperService.showAddNewSubAccBtn = false;
  }
  CurrentImagesCount: number = 0;
  ShowImagePicker: boolean = true;
  public Root_Category_Option: Select2Options;

  @ViewChild("offCanvas") divView: ElementRef;

  @ViewChild("addimage")
  private InputFileComponent_Add: InputFileComponent;

  @ViewChild("EditImage")
  private InputFileComponent_Edit: InputFileComponent;

  @ViewChild("inputfile")
  private InputFileComponent: InputFileComponent;

  ngOnInit() {

    // window.addEventListener('scroll', this.scroll, true);
    $('#wizard1').steps({
      headerTag: 'h3',
      bodyTag: 'section',
      autoFocus: true,
      enablePagination: false,
      titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
      onFinished: (event, currentIndex) => {
        //this.Form_AddUser_Process();
      },
      labels: {
        next: "Save",
      }
    });
    this._HelperService.StopClickPropogation();
    Feather.replace();
    this._HelperService.ValidateData();

    this.InitBackDropClickEvent();
    this.MerchnatCategories_Setup();
    this.InitColConfig();
    this.Form_Add_Load();
    this.Form_Edit_Load();
    this.Root_Category_Load();
    this.Categories_GetDetails();
    this.Form_Edit_Root_Load();
    // this._HelperService.StopClickPropogation();
    // this._ObjectSubscription = this._HelperService.ObjectCreated.subscribe(value => {
    //   this.MerchnatCategories_GetData();
    // });
  }
  // scroll = (event): void => {
  //   $(".daterangepicker").hide();
  //   $(".form-daterangepicker").blur();
  // };
  // openDatepicker(event) {
    
  //   $(".daterangepicker").show();
  // }


  ResetImagePlaceholder: boolean = true
  ResetImage(): void {
    this.ResetImagePlaceholder = false;
    this._ChangeDetectorRef.detectChanges();
    this.ResetImagePlaceholder = true;
    this._ChangeDetectorRef.detectChanges();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;

    //this.getdata();
  }
  resetImage() {
    this._HelperService.Icon_Crop_Clear()
    $("#fileControl").val('');
    $("#EditfileControl").val('');
    $("#editrootcatagoryImage").val('');


    // this.InputFileComponent_Add.files.pop();
    // this.InputFileComponent_Edit.files.pop();


  }


  setImage() {
    // this._HelperService._Icon_Cropper_Data
    this.SavedDealImages = this._HelperService._Icon_Cropper_Image
    this.SubCategorySavedDealImages = this._HelperService._Icon_Cropper_Image;
  }
  onImgError(event) {
    event.target.src = "https://s3.eu-west-2.amazonaws.com/cdn.thankucash.com/defaults/defaulticon.png";
  }

  detectFiles(event) {
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
  }
  images = [];
  localimg = new Array();

  uploadFiles() {
    //       const frmData = new FormData();

    //       // for (var i = 0; i < this.myFiles.length; i++) { 
    //       //   frmData.append("fileUpload", this.myFiles[i]);
    //       // }




    //       this.Subcategories[this.sub_cat_ind]['IconContent'] = {
    //         "Name": this.Subcategories[this.sub_cat_ind]['Name'],
    //        // "Extension": this.formData.files.extension,
    //        // "Content": [idr jo pugin use kiya hai uska data]
    // }

    this.localimg = this.images;
    localStorage.setItem("localimg", JSON.stringify(this.localimg));
    //$("#wizard1").steps("next", {});
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          // this.images.push(event.target.result); 
          this._HelperService._Icon_Cropper_Data.Content = event.target.result.replace("data:image/png;base64,", "")
            .replace("data:image/jpg;base64,", "")
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/gif;base64,", "");

          this.images.push({ Name: "", Content: this._HelperService._Icon_Cropper_Data.Content, Extension: "", TypeCode: "", Height: "320", Width: "360" });
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }


  }
  private InitImagePickerAdd(InputFileComponent: InputFileComponent) {
    if (InputFileComponent != undefined) {
      this.CurrentImagesCount = 0;
      this._HelperService._InputFileComponent = InputFileComponent;
      InputFileComponent.onChange = (files: Array<InputFile>): void => {
        if (files.length >= this.CurrentImagesCount) {
          this._HelperService._SetFirstImageOrNone(InputFileComponent.files);
        }
        this.CurrentImagesCount = files.length;
      };
    }
  }

  private InitImagePicker(previewurl?: string) {
    if (this.InputFileComponent != undefined) {
      this.CurrentImagesCount = 0;
      if (previewurl) {
        this.InputFileComponent.files[0] = {};
        this.InputFileComponent.files[0].preview = previewurl;
      }
      this._HelperService._InputFileComponent = this.InputFileComponent;
      this.InputFileComponent.onChange = (files: Array<InputFile>): void => {
        if (files.length >= this.CurrentImagesCount) {
          this._HelperService._SetFirstImageOrNone(this.InputFileComponent.files);
        }
        this.CurrentImagesCount = files.length;
      };
    }
  }

  Root_Category_Change(event: any) {
    this.rootCategoryId = event.value
    this.Form_Add.patchValue(
      {
        RootCategoryId: event.data[0].ReferenceId,
        RootCategoryKey: event.data[0].ReferenceKey,
      }
    );
  }

  Root_Category_Load() {

    var PlaceHolder = this._HelperService.AppConfig.CommonResource.Select;
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.Core.getcategories,
      Location: this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceKey",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: true,
          Text: false,
        },
        {
          SystemName: "Name",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true
        },
      ]
    };

    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    this.Root_Category_Option = {
      placeholder: 'Select Category',
      ajax: _Transport,
      multiple: false,
      allowClear: true,
    };
  }

  ngOnDestroy(): void {
    // window.removeEventListener('scroll', this.scroll, true);
    try {
      this._ObjectSubscription.unsubscribe();
    } catch (error) {

    }
  }
  InitBackDropClickEvent(): void {
    var backdrop: HTMLElement = document.getElementById("backdrop");

    backdrop.onclick = () => {
      $(this.divView.nativeElement).removeClass('show');
      backdrop.classList.remove("show");
    };
  }

  removeImage(): void {
    this.CurrentImagesCount = 0;
    this._HelperService.Icon_Crop_Clear();
  }

  Form_AddUser_Open() {
    this._Router.navigate([
      this._HelperService.AppConfig.Pages.ThankUCash.PanelConsole
        .MerchantOnboarding,
    ]);
  }



  //#region columnConfig

  TempColumnConfig: any = [
    {
      Name: "Status",
      Value: true,
    },
    {
      Name: "City",
      Value: true,
    },
    {
      Name: "Contact",
      Value: true,
    },
    {
      Name: "Stores",
      Value: true,
    },
    {
      Name: "Reward",
      Value: true,
    },
    {
      Name: "POS",
      Value: true,
    },
    {
      Name: "ActivePOS",
      Value: true,
    },
    {
      Name: "RM",
      Value: true,
    },
    {
      Name: "Added",
      Value: true,
    },
  ];

  ColumnConfig: any = [
    {
      Name: "Status",
      Value: true,
    },
    {
      Name: "City",
      Value: true,
    },
    {
      Name: "Contact",
      Value: true,
    },
    {
      Name: "Stores",
      Value: true,
    },
    {
      Name: "Reward",
      Value: true,
    },
    {
      Name: "POS",
      Value: true,
    },
    {
      Name: "ActivePOS",
      Value: true,
    },
    {
      Name: "RM",
      Value: true,
    },
    {
      Name: "Added",
      Value: true,
    },
  ];

  SavedDealImages: any;
  Categories_GetDetails() {
    var rootCategory = this._HelperService.GetStorage('hcarootcategory');
    var pData = {
      Task: 'getcategory',
      ReferenceId: rootCategory.ReferenceId,
      ReferenceKey: rootCategory.ReferenceKey
    };
    

    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._userAccount = _Response.Result;
         
          this._userAccount.CreateDate_Time = this._HelperService.GetDateTimeS(this._userAccount.CreateDate);
          this._userAccount.ModifyDate_Time = this._HelperService.GetDateTimeS(this._userAccount.ModifyDate);

          if (this._userAccount.IconUrl != undefined && this._userAccount.IconUrl != null && this._userAccount.IconUrl.length > 0) {
            this.SavedDealImages = this._userAccount.IconUrl;
          }

          this._ChangeDetectorRef.detectChanges();
          // this._HelperService.OpenModal('Form_Edit_Content');

        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      });
  }

  InitColConfig() {
    var MerchantTableConfig = this._HelperService.GetStorage("BMerchantTable");
    var ColConfigExist: boolean =
      MerchantTableConfig != undefined && MerchantTableConfig != null;
    if (ColConfigExist) {
      this.ColumnConfig = MerchantTableConfig.config;
      this.TempColumnConfig = this._HelperService.CloneJson(
        MerchantTableConfig.config
      );
    }
  }

  OpenEditColModal() {
    this._HelperService.OpenModal("EditCol");
  }

  SaveEditCol() {
    this.ColumnConfig = this._HelperService.CloneJson(this.TempColumnConfig);
    this._HelperService.SaveStorage("BMerchantTable", {
      config: this.ColumnConfig,
    });
    this._HelperService.CloseModal("EditCol");
  }

  //#endregion

  //#region Categories
  public CurrentRequest_Key: string;

  public MerchnatCategories_Config: OList;
  MerchnatCategories_Setup() {
    var rootCategory = this._HelperService.GetStorage('hcarootcategory');
    this.MerchnatCategories_Config = {
      Id: null,
      Type: "all",
      Sort: null,
      Task: this._HelperService.AppConfig.Api.Core.getsubcategories,
      Location: this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories,
      Title: 'Subcategories',
      StatusType: 'default',
      ReferenceId: rootCategory.ReferenceId,
      ReferenceKey: rootCategory.ReferenceKey,
      // SearchBaseCondition: this._HelperService.GetSearchConditionStrict('', "TypeCode", this._HelperService.AppConfig.DataType.Text, this._HelperService.AppConfig.HelperTypes.MerchantCategories, "="),
      TableFields: [
        {
          DisplayName: 'Name',
          SystemName: 'Name',
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: '',
          Show: true,
          Search: true,
          Sort: true,
          ResourceId: null,
        },
        {
          DisplayName: this._HelperService.AppConfig.CommonResource.CreateDate,
          SystemName: 'CreateDate',
          DataType: this._HelperService.AppConfig.DataType.Date,
          Class: 'td-date',
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
          IsDateSearchField: true,
        },
        {
          DisplayName: this._HelperService.AppConfig.CommonResource.ModifyDate,
          SystemName: 'ModifyDate',
          DataType: this._HelperService.AppConfig.DataType.Date,
          Class: 'td-date',
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
        },
      ]

    };
    this.MerchnatCategories_Config = this._DataHelperService.List_Initialize(
      this.MerchnatCategories_Config
    );

    this._HelperService.Active_FilterInit(
      this._HelperService.AppConfig.FilterTypeOption.Cashier,
      this.MerchnatCategories_Config
    );

    this.MerchnatCategories_GetData();
  }
  MerchnatCategories_ToggleOption(event: any, Type: any) {

    if (event != null) {
      for (let index = 0; index < this.MerchnatCategories_Config.Sort.SortOptions.length; index++) {
        const element = this.MerchnatCategories_Config.Sort.SortOptions[index];
        if (event.SystemName == element.SystemName) {
          element.SystemActive = true;
        }
        else {
          element.SystemActive = false;
        }
      }
    }

    this._HelperService.Update_CurrentFilterSnap(
      event,
      Type,
      this.MerchnatCategories_Config


    );

    this.MerchnatCategories_Config = this._DataHelperService.List_Operations(
      this.MerchnatCategories_Config,
      event,
      Type
    );

    if (
      (this.MerchnatCategories_Config.RefreshData == true)
      && this._HelperService.DataReloadEligibility(Type)
    ) {
      this.MerchnatCategories_GetData();
    }

  }
  timeout = null;
  MerchnatCategories_ToggleOptionSearch(event: any, Type: any) {

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (event != null) {
        for (let index = 0; index < this.MerchnatCategories_Config.Sort.SortOptions.length; index++) {
          const element = this.MerchnatCategories_Config.Sort.SortOptions[index];
          if (event.SystemName == element.SystemName) {
            element.SystemActive = true;
          }
          else {
            element.SystemActive = false;
          }
        }
      }

      this._HelperService.Update_CurrentFilterSnap(
        event,
        Type,
        this.MerchnatCategories_Config


      );

      this.MerchnatCategories_Config = this._DataHelperService.List_Operations(
        this.MerchnatCategories_Config,
        event,
        Type
      );

      if (
        (this.MerchnatCategories_Config.RefreshData == true)
        && this._HelperService.DataReloadEligibility(Type)
      ) {
        this.MerchnatCategories_GetData();
      }

    }, this._HelperService.AppConfig.SearchInputDelay);

  }

  MerchnatCategories_GetData() {

    var TConfig = this._DataHelperService.List_GetData(
      this.MerchnatCategories_Config
    );
    this.MerchnatCategories_Config = TConfig;
  }
  MerchnatCategories_RowSelected(ReferenceData) {
    var ReferenceKey = ReferenceData.ReferenceKey;
    this._HelperService.AppConfig.ActiveReferenceKey = ReferenceKey;
    // this.MerhchantCategories_GetDetails();
  }

  //#endregion
  isEditForm: boolean = false;
  Merchant_GetDetails(Refid, Refkey) {
    var pData = {
      Task: 'getcategory',
      ReferenceId: Refid,
      ReferenceKey: Refkey
    };

    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          // this._CoreUsage = _Response.Result;
          this._userAccount = _Response.Result;
          this.isEditForm = true;
          if (this._userAccount.IconUrl != undefined && this._userAccount.IconUrl != null && this._userAccount.IconUrl.length > 0) {
            this.SavedDealImages = this._userAccount.IconUrl;
          }
          this._ChangeDetectorRef.detectChanges();
          this._HelperService.OpenModal('Form_Edit_Root_Content');
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      });
  }
  SubCategorySavedDealImages: any;
  isSubcategoryEditForm: boolean = false;
  MerchantCategories_GetDetails(Refid, Refkey) {
    var pData = {
      Task: 'getsubcategory',
      ReferenceId: Refid,
      ReferenceKey: Refkey
    };

    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._CoreUsage = _Response.Result;
          this.isSubcategoryEditForm = true;
          if (this._CoreUsage.IconUrl != undefined && this._CoreUsage.IconUrl != null && this._CoreUsage.IconUrl.length > 0) {
            this.SubCategorySavedDealImages = this._CoreUsage.IconUrl;
          }

          this._ChangeDetectorRef.detectChanges();
          this._HelperService.OpenModal('Form_Edit_Content');

        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      });
  }
  Form_Add: FormGroup;
  Form_Add_Show() {
    this.Form_Add_Clear();
    this._HelperService.Icon_Crop_Clear();
    this._HelperService.OpenModal('Form_Add_Content');
    setTimeout(() => {
      this.InitImagePickerAdd(this.InputFileComponent_Add);
    }, 100);

  }
  Form_Add_Close() {
    // this._Router.navigate([this._HelperService.AppConfig.Pages.System.CoreHelpers]);
    this._HelperService.CloseModal('Form_Add_Content');
    this.Form_Add_Clear();
  }
  Form_Add_Load() {
    var rootCategory = this._HelperService.GetStorage('hcarootcategory');
    this._HelperService._Icon_Cropper_Data.Width = 128;
    this._HelperService._Icon_Cropper_Data.Height = 128;
    this._HelperService.Icon_Crop_Clear();
    this.InitImagePickerAdd(this.InputFileComponent_Add);
    this.Form_Add = this._FormBuilder.group({
      Task: this._HelperService.AppConfig.Api.Core.savesubcategory,
      CategoryId: rootCategory.ReferenceId,
      CategoryKey: rootCategory.ReferenceKey,
      Name: [null, Validators.required],
      StatusCode: 'default.active',
      IconContent: this._HelperService._Icon_Cropper_Data
    });
  }
  Form_Add_Clear() {
    this.Form_Add.reset();
    this._HelperService._FileSelect_Icon_Reset();
    this._HelperService._FileSelect_Poster_Reset();
    this.Form_Add_Load();
  }



  Form_Edit_Content: FormGroup;
  Form_Edit: FormGroup;
  Form_Edit_Show(ReferenceData) {
    this.ReferenceId = ReferenceData.ReferenceId;
    this.ReferenceKey = ReferenceData.ReferenceKey;
    this.RootCategoryId = ReferenceData.RootCategoryId;


    this.MerchantCategories_GetDetails(this.ReferenceId, this.ReferenceKey);
  }
  Form_Edit_Close() {
    this.Form_Edit_Clear();
    this.isSubcategoryEditForm = false;
    this._HelperService.CloseModal('Form_Edit_Content');
    // this._Router.navigate([this._HelperService.AppConfig.Pages.System.CoreHelpers]);
    //this.unclick();
    // this.MerchnatCategories_Setup();
  }
  Form_Edit_Load() {
    // this.InitImagePicker(this.InputFileComponent_Edit);

    this.Form_Edit = this._FormBuilder.group({
      OperationType: 'new',
      Task: this._HelperService.AppConfig.Api.Core.updatesubcategory,
      ReferenceId: this.ReferenceId,
      ReferenceKey: this.ReferenceKey,
      Name: [null, Validators.required],
      StatusCode: [this._HelperService.AppConfig.StatusCode, Validators.required],
      RootCategoryId: this.RootCategoryId,
      IconContent: this._HelperService._Icon_Cropper_Data,
      // Commission: [null, Validators.required]
    });
  }
  showAccountTypeEdit: boolean = true;
  Form_Edit_Clear() {
    //#region reload Add form 
    this.showAccountTypeEdit = false;
    setTimeout(() => {
      this.showAccountTypeEdit = true;
    }, 100);
    //#endregion
  }
  Form_AddApp_Process(_FormValue: any) {
    this._HelperService.ToggleField = true;
    this._HelperService.IsFormProcessing = true;
    _FormValue.IconContent = this._HelperService._Icon_Cropper_Data;
    if (this._HelperService._Icon_Cropper_Data.Content != null) {
      _FormValue.IconContent = this._HelperService._Icon_Cropper_Data;
    }
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, _FormValue);

    _OResponse.subscribe(
      _Response => {
        this._HelperService.ToggleField = false;
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this.CategeoryResponse = _Response.Result
          this._HelperService.NotifySuccess("Category Added Successfully");
          this.InputFileComponent_Add = null;
          this.imageChangedEvent.target.value = null;
          this.ResetImage();
          this._HelperService._FileSelect_Icon_Reset();
          this.MerchnatCategories_Setup();
          this.Form_Add_Close();
          this._HelperService.Icon_Crop_Clear();
          $("#wizard1").steps("next", {});;
          if (_FormValue.OperationType == 'edit') {
            this.Form_Add_Close();
            // this._Router.navigate([this._HelperService.AppConfig.Pages.System.AddCoreHelpers, _Response.Result.ReferenceKey]);
          }
          else if (_FormValue.OperationType == 'close') {
            this.imageChangedEvent.target.value = null;
            this.ResetImage();
            this._HelperService._FileSelect_Icon_Reset();
            this._HelperService.Icon_Crop_Clear();
            this.Form_Add_Close();
          }
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      });
  }

  Form_Edit_Process(_FormValue: any) {
    _FormValue.ReferenceKey = this.ReferenceKey;
    _FormValue.ReferenceId = this.ReferenceId;
    _FormValue.RootCategoryId = this.RootCategoryId;
    this._HelperService.IsFormProcessing = true;
    _FormValue.IconContent = this._HelperService._Icon_Cropper_Data;
    if (this._HelperService._Icon_Cropper_Data.Content != null) {
      _FormValue.IconContent = this._HelperService._Icon_Cropper_Data;
    }
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, _FormValue);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.NotifySuccess("Subcategory Updated successfully");
          this.Form_Edit_Clear();
          this.Form_Edit_Close();
          this.MerchnatCategories_GetData();
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      });


  }

  Form_Edit_Root: FormGroup;
  Form_Edit_Root_Show(ReferenceData) {
    this.ReferenceId = ReferenceData.ReferenceId;
    this.ReferenceKey = ReferenceData.ReferenceKey;
    this.RootCategoryId = ReferenceData.RootCategoryId;


    this.Merchant_GetDetails(this.ReferenceId, this.ReferenceKey);
  }
  Form_Edit_Root_Close() {
    this.isEditForm = false;
    this.Form_Edit_Root_Clear();
    // this.MerchnatCategories_Setup();
    this._HelperService.CloseModal('Form_Edit_Root_Content');
  }

  Form_Edit_Root_Clear() {
    this._HelperService._FileSelect_Icon_Reset();
    this._HelperService._FileSelect_Poster_Reset();
  }

  Form_Edit_Root_Load() {
    // this.InitImagePicker(this.InputFileComponent_Edit);

    this.Form_Edit_Root = this._FormBuilder.group({
      OperationType: 'new',
      Task: this._HelperService.AppConfig.Api.Core.updatecategory,
      ReferenceId: this.ReferenceId,
      ReferenceKey: this.ReferenceKey,
      Name: [null, Validators.required],
      StatusCode: [this._HelperService.AppConfig.StatusCode, Validators.required],
      RootCategoryId: this.RootCategoryId,
      IconContent: this._HelperService._Icon_Cropper_Data,
    });
  }

  Form_Edit_Root_Process(_FormValue: any) {
    _FormValue.ReferenceKey = this.ReferenceKey;
    _FormValue.ReferenceId = this.ReferenceId;
    _FormValue.RootCategoryId = this.RootCategoryId;

    this._HelperService.IsFormProcessing = true;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, _FormValue);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          // this.Merchant_GetDetails(this.ReferenceKey,this.ReferenceId);
          // this.Form_Edit_Root_Clear();
          this.Categories_GetDetails();
          this.Form_Edit_Root_Close();
          this._HelperService.NotifySuccess(_Response.Message);
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      });



  }

  SetOtherFilters(): void {
    this.MerchnatCategories_Config.SearchBaseConditions = [];
    // this.MerchnatCategories_Config.SearchBaseCondition = this._HelperService.GetSearchConditionStrict('', "TypeCode", this._HelperService.AppConfig.DataType.Text, this._HelperService.AppConfig.HelperTypes.MerchantCategories, "=");

    var CurrentIndex = this._HelperService.FilterSnap.OtherFilters.findIndex((filter) => (filter.data[0].OtherType == this._HelperService.AppConfig.OtherFilters.Merchant.Owner));
    if (CurrentIndex != -1) {
    }
  }

  TUTr_GetData() {
    // this.GetOverviews(this.TUTr_Config, this._HelperService.AppConfig.Api.ThankUCash.GetPurchaseHistoryOverview);
    var TConfig = this._DataHelperService.List_GetData(this.TUTr_Config);
    this.TUTr_Config = TConfig;
  }
  public TUTr_Config: OList;

  TUTr_Setup() {

    this.TUTr_Config = {
      Id: null,
      Sort: null,
      Task: this._HelperService.AppConfig.Api.Core.getsubcategories,
      Location: this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories,
      Title: "Deal",
      StatusType: "AppPromots",

      DefaultSortExpression: "CreateDate desc",
      //SearchBaseCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'dealcode.unused', '=='),
      TableFields: [
        {
          DisplayName: "Name",
          SystemName: "Name",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "",
          Show: true,
          Search: true,
          Sort: true,
          ResourceId: null,
        },
        {
          DisplayName: "Added on",
          SystemName: "CreateDate",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "",
          Show: true,
          Search: true,
          Sort: true,
          ResourceId: null,
        },
        {
          DisplayName: "Updated on",
          SystemName: "ModifyDate",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "",
          Show: true,
          Search: true,
          Sort: true,
          ResourceId: null,
        },
      ]
    };

    this.TUTr_Config = this._DataHelperService.List_Initialize(this.TUTr_Config);
    this._HelperService.Active_FilterInit(
      this._HelperService.AppConfig.FilterTypeOption.SoldHistory,
      this.TUTr_Config
    );
    this.TUTr_GetData();
  }

  //#region filterOperations

  Active_FilterValueChanged(event: any) {
    this._HelperService.Active_FilterValueChanged(event);
    this._FilterHelperService.SetRequestHistoryConfig(this.MerchnatCategories_Config);

    //#region setOtherFilters
    // this.SetOtherFilters();
    //#endregion

    this.MerchnatCategories_GetData();
  }

  RemoveFilterComponent(Type: string, index?: number): void {
    this._FilterHelperService._RemoveFilter_RequestHistory(Type, index);
    this._FilterHelperService.SetRequestHistoryConfig(this.MerchnatCategories_Config);
    this.SetOtherFilters();
    this.MerchnatCategories_GetData();
  }

  categoryDeal: any = {};
  detetecategoryId: any;
  detetecategoryKey: any;

  UnMarkAscategory(ReferenceData: any): void {
    this.categoryDeal = {};
    this.detetecategoryId = ReferenceData.ReferenceId;
    this.detetecategoryKey = ReferenceData.ReferenceKey;

    swal({
      position: "center",
      // title: this._HelperService.AppConfig.CommonResource.RemoveAppPromoteTitle,
      title: this._HelperService.AppConfig.CommonResource.RemoveSubCategoryTitle,
      text: this._HelperService.AppConfig.CommonResource.RemoveCategoryHelp,
      animation: false,
      customClass: this._HelperService.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this._HelperService.AppConfig.Color_Red,
      cancelButtonColor: this._HelperService.AppConfig.Color_Grey,
      confirmButtonText: this._HelperService.AppConfig.CommonResource.Continue,
      cancelButtonText: this._HelperService.AppConfig.CommonResource.Cancel

    }).then((result) => {
      if (result.value) {

        this._HelperService.IsFormProcessing = true;
        var PData =
        {
          Task: this._HelperService.AppConfig.Api.ThankUCash.deletecategory,
          ReferenceId: this.detetecategoryId,
          ReferenceKey: this.detetecategoryKey,
          // StatusCode: this.selectedStatusItem.statusCode,

        }
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.Console.V3.Categories, PData);
        _OResponse.subscribe(
          _Response => {
            if (_Response.Status == this._HelperService.StatusSuccess) {
              this._HelperService.NotifySuccess("Category Deleted Successfully.");
              this.TUTr_Setup();
              // this._HelperService.CloseModal('FlashDeal');
              this._HelperService.IsFormProcessing = false;

            }
            else {
              this._HelperService.NotifyError(_Response.Message);
            }
          }
          ,
          _Error => {
            this._HelperService.IsFormProcessing = false;
            this._HelperService.HandleException(_Error);
            this._HelperService.ToggleField = false;
          });

      }
    });

  }

  _DealConfig =
    {

      Images: [],
      StartDateConfig: {
      },
      EndDateConfig: {
      },
      DefaultStartDate: null,
      DefaultEndDate: null,
      DealImages: [],
      StartDate: null,
      EndDate: null,
    }
  //Edit categoryd deal--
  //  Form_Managecategory:FormGroup;
  ManagecategoryDeal: any = {};
  SelectedDealStartDatecategory: any;
  SelectedDealEndDatecategory: any;
  Selectedurl: any;
  SelectedRole: any;
  SelectedDealStartDatecategory1: any;
  SelectedDealEndDatecategory1: any;
  // NavigationType:any;
  NavigationData: any;
  ManageReferenceKey: any;
  ManageReferenceId: any;
  Selectedimage: any
  DealStatus: any;
  Form_Managecategory: FormGroup;
  checklist: boolean = false;
  CheckStatus: any;

  ManageAscategory(ReferenceData: any): void {

    this._HelperService.OpenModal('ManagecategoryDeal');
  }


  Save_NewFilter() {
    swal({
      position: "center",
      title: this._HelperService.AppConfig.CommonResource.SaveFilterTitle,
      text: this._HelperService.AppConfig.CommonResource.SaveFilterHelp,
      input: "text",
      inputPlaceholder: this._HelperService.AppConfig.CommonResource.FilterName,
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
        //maxLength: "4",
        minLength: "4",
      },
      animation: false,
      customClass: this._HelperService.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this._HelperService.AppConfig.Color_Green,
      cancelButtonColor: this._HelperService.AppConfig.Color_Grey,
      confirmButtonText: "Save",
      cancelButtonText: this._HelperService.AppConfig.CommonResource.Cancel,
    }).then((result) => {
      if (result.value) {
        this._HelperService._RefreshUI = false;
        this._ChangeDetectorRef.detectChanges();

        this._FilterHelperService._BuildFilterName_RequestHistory(result.value);
        this._HelperService.Save_NewFilter(
          this._HelperService.AppConfig.FilterTypeOption.Cashier
        );

        this._HelperService._RefreshUI = true;
        this._ChangeDetectorRef.detectChanges();
      }
    });
  }

  Delete_Filter() {

    swal({
      position: "center",
      title: this._HelperService.AppConfig.CommonResource.DeleteTitle,
      text: this._HelperService.AppConfig.CommonResource.DeleteHelp,
      animation: false,
      customClass: this._HelperService.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this._HelperService.AppConfig.Color_Red,
      cancelButtonColor: this._HelperService.AppConfig.Color_Grey,
      confirmButtonText: this._HelperService.AppConfig.CommonResource.Continue,
      cancelButtonText: this._HelperService.AppConfig.CommonResource.Cancel

    }).then((result) => {
      if (result.value) {
        this._HelperService._RefreshUI = false;
        this._ChangeDetectorRef.detectChanges();

        this._HelperService.Delete_Filter(
          this._HelperService.AppConfig.FilterTypeOption.Cashier
        );
        this._FilterHelperService.SetRequestHistoryConfig(this.MerchnatCategories_Config);
        this.MerchnatCategories_GetData();

        this._HelperService._RefreshUI = true;
        this._ChangeDetectorRef.detectChanges();
      }
    });

  }

  ApplyFilters(event: any, Type: any, ButtonType: any): void {
    this._HelperService.MakeFilterSnapPermanent();
    this.MerchnatCategories_GetData();

    if (ButtonType == 'Sort') {
      $("#MerchnatCategories_sdropdown").dropdown('toggle');
    } else if (ButtonType == 'Other') {
      $("#MerchnatCategories_fdropdown").dropdown('toggle');
    }

    this.ResetFilterUI(); this._HelperService.StopClickPropogation();
  }

  ResetFilters(event: any, Type: any): void {
    this._HelperService.ResetFilterSnap();
    this._FilterHelperService.SetRequestHistoryConfig(this.MerchnatCategories_Config);
    this.SetOtherFilters();

    this.MerchnatCategories_GetData();

    this.ResetFilterUI(); this._HelperService.StopClickPropogation();
  }

  //#endregion

  ResetFilterUI(): void {
    this.ResetFilterControls = false;
    this._ChangeDetectorRef.detectChanges();
    this.ResetFilterControls = true;
    this._ChangeDetectorRef.detectChanges();
  }




  clicked() {
    $(this.divView.nativeElement).addClass('show');
    var backdrop: HTMLElement = document.getElementById("backdrop");
    backdrop.classList.add("show");
  }
  unclick() {
    $(this.divView.nativeElement).removeClass('show');
    var backdrop: HTMLElement = document.getElementById("backdrop");
    backdrop.classList.remove("show");
  }

  Delete_Confirm() {
    swal({
      //title: this._HelperService.AppConfig.CommonResource.DeleteTitle,
      title: "Delete Business Categories?",
      text: this._HelperService.AppConfig.CommonResource.DeleteHelp,
      showCancelButton: true,
      position: this._HelperService.AppConfig.Alert_Position,
      animation: this._HelperService.AppConfig.Alert_AllowAnimation,
      customClass: this._HelperService.AppConfig.Alert_Animation,
      allowOutsideClick: this._HelperService.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this._HelperService.AppConfig.Alert_AllowEscapeKey,
      confirmButtonColor: this._HelperService.AppConfig.Color_Red,
      cancelButtonColor: this._HelperService.AppConfig.Color_Grey,
      confirmButtonText: this._HelperService.AppConfig.CommonResource.Continue,
      cancelButtonText: this._HelperService.AppConfig.CommonResource.Cancel,
      input: 'text',
      inputPlaceholder: this._HelperService.AppConfig.CommonResource.AccessPin,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        maxLength: "4",
        minLength: "4"
      },
      inputClass: 'swalText',
      inputValidator: function (value) {
        if (value === '' || value.length < 4) {
          return 'Enter your 4 digit pin!'
        }
      },
    }).then((result) => {
      if (result.value) {
        this._HelperService.IsFormProcessing = true;
        var pData = {
          Task: this._HelperService.AppConfig.Api.Core.DeleteCoreCommon,
          ReferenceKey: this._HelperService.AppConfig.ActiveReferenceKey,
          AuthPin: result.value
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, pData);
        _OResponse.subscribe(
          _Response => {
            this._HelperService.IsFormProcessing = false;
            if (_Response.Status == this._HelperService.StatusSuccess) {
              this.Form_Edit_Close();
              this._HelperService.NotifySuccess("Category Deleted Successfully");
              this._HelperService.CloseModal('ModalDetails');
            }
            else {
              this._HelperService.NotifyError(_Response.Message);
            }
          },
          _Error => {

            this._HelperService.HandleException(_Error);
          });
      }
    });
  }


  public _userAccount: OCoreUsage =
    {
      Count: 0,
      CreateDate: null,
      CreatedByDisplayName: null,
      CreatedByIconUrl: null,
      CreatedByKey: null,
      Commission: null,
      Data: null,
      Description: null,
      PlatformCharge: null,
      HelperCode: null,
      HelperName: null,
      IconUrl: null,
      ModifyByDisplayName: null,
      ModifyByIconUrl: null,
      ModifyByKey: null,
      ModifyDate: null,
      Name: null,
      ParentCode: null,
      ParentKey: null,
      ParentName: null,
      PosterUrl: null,
      Reference: null,
      ReferenceKey: null,
      ReferenceId: null,
      RootCategoryId: null,
      Sequence: null,
      SubItemsCount: null,
      StatusCode: null,
      StatusId: null,
      StatusName: null,
      SubParentCode: null,
      SubParentKey: null,
      SubParentName: null,
      SubValue: null,
      SystemName: null,
      TypeCode: null,
      TypeName: null,
      UserAccountDisplayName: null,
      UserAccountIconUrl: null,
      UserAccountKey: null,
      Value: null,
      StatusI: null,
      CreateDateS: null,
      ModifyDateS: null,
      CreateDate_Time: null,
      ModifyDate_Time: null
    }

  public _CoreUsage: OCoreUsage =
    {
      Count: 0,
      CreateDate: null,
      CreatedByDisplayName: null,
      CreatedByIconUrl: null,
      CreatedByKey: null,
      Commission: null,
      Data: null,
      Description: null,
      PlatformCharge: null,
      HelperCode: null,
      HelperName: null,
      IconUrl: null,
      ModifyByDisplayName: null,
      ModifyByIconUrl: null,
      ModifyByKey: null,
      ModifyDate: null,
      Name: null,
      ParentCode: null,
      ParentKey: null,
      ParentName: null,
      PosterUrl: null,
      Reference: null,
      ReferenceKey: null,
      ReferenceId: null,
      RootCategoryId: null,
      Sequence: null,
      SubItemsCount: null,
      StatusCode: null,
      StatusId: null,
      StatusName: null,
      SubParentCode: null,
      SubParentKey: null,
      SubParentName: null,
      SubValue: null,
      SystemName: null,
      TypeCode: null,
      TypeName: null,
      UserAccountDisplayName: null,
      UserAccountIconUrl: null,
      UserAccountKey: null,
      Value: null,
      StatusI: null,
      CreateDateS: null,
      ModifyDateS: null,
      CreateDate_Time: null,
      ModifyDate_Time: null

    }
}

export class OCoreUsage {
  public Reference: string;
  public ReferenceKey: string;
  public ReferenceId: string;
  public Commission: number;
  public RootCategoryId: number;
  public SystemName: string;
  public UserAccountKey: string;
  public UserAccountDisplayName: string;
  public UserAccountIconUrl: string;
  public TypeCode: string;
  public TypeName: string;
  public HelperCode: string;
  public HelperName: string;
  public ParentKey: string;
  public ParentCode: string;
  public ParentName: string;
  public SubParentKey: string;
  public SubParentCode: string;
  public SubParentName: string;
  public PlatformCharge?: any;
  public Name: string;
  public Value: string;
  public SubValue: string;
  public Description: string;
  public Data: string;
  public Sequence: number = 0;
  public Count: number = 0;
  public SubItemsCount: number = 0;
  public IconUrl: string;
  public PosterUrl: string;
  public CreateDate: Date;
  public CreatedByKey: string;
  public CreatedByDisplayName: string;
  public CreatedByIconUrl: string;
  public ModifyDate: Date;
  public ModifyByKey: string;
  public ModifyByDisplayName: string;
  public ModifyByIconUrl: string;
  public StatusId: number = 0;
  public StatusCode: string;
  public StatusName: string;
  public StatusI: string;
  public CreateDateS: string;
  public ModifyDateS: string;
  public CreateDate_Time: Date;
  public ModifyDate_Time: Date;
}
