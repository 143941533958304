import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/onboarding/register/register.component';
import { RegisterStoreComponent } from './auth/onboarding/registerstore/registerstore.component';
import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { AccessComponent } from './auth/access/access.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslaterModule, HelperService, DataHelperService, FilterHelperService } from './service/service';
import { AgmCoreModule } from '@agm/core';
// import { NgProgressModule } from '@ngx-progressbar/core';
// import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { Ng2FileInputModule } from 'ng2-file-input';
import { AgmOverlays } from "agm-overlays"
import { Ng5SliderModule } from 'ng5-slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ArchwizardModule } from 'angular-archwizard';
import { Angular4PaystackModule } from 'angular4-paystack';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RegisterRewardComponent } from './auth/onboarding/registerreward/registerreward.component';
import { RegisterCompleteComponent } from './auth/onboarding/registercomplete/registercomplete.component';
import { ChartsModule } from 'ng2-charts';
import { from } from 'rxjs';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgOtpInputModule } from 'ng-otp-input'
import { Select2Module } from "ng2-select2";
import { ChangePasswordComponent } from './auth/verifybulkmerchants/changepassword/changepassword.component';
import { VerifyNumberComponent } from './auth/verifybulkmerchants/verifynumber/verifynumber.component';
import { VerifyPinComponent } from './auth/verifybulkmerchants/verifypin/verifypin.component';

// import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MerchantguardGuard } from './service/guard/merchantguard.guard';
import { ExpcompComponent } from './expcomp/expcomp.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NgxGalleryModule } from 'ngx-gallery';
import { TUNotFoundComponent } from './panel/accounts/tunotfound/tunotfound.component';
import { LoginRequestComponent } from './auth/loginrequest/loginrequest.component';
import { LoginConfirmComponent } from './auth/loginconfirm/loginconfirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FileManagerModule } from 'src/app/modules/file-manager/file-manager.module';
import { TusubcategoryModule } from './panel/administration/tusubcategories/tusubcategories.module';
import { TuNotAccessComponent } from './panel/accounts/tunotaccess/tunotaccess.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginRequestComponent,
    LoginConfirmComponent,
    RegisterComponent,
    RegisterStoreComponent,
    RegisterRewardComponent,
    RegisterCompleteComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccessComponent,
    ChangePasswordComponent,
    VerifyPinComponent,
    VerifyNumberComponent,
    ExpcompComponent,
    TUNotFoundComponent,
    TuNotAccessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslaterModule,
    Ng5SliderModule,
    ArchwizardModule,
    ImageCropperModule,
    AgmOverlays,
    Angular4PaystackModule,
    NgxJsonViewerModule,
    GooglePlaceModule,
    ChartsModule,
    NgxGalleryModule,
    BrowserAnimationsModule,
    // NgProgressModule,
    // NgProgressHttpModule,
    // LoadingBarHttpModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    RecaptchaModule,
    NgOtpInputModule,
    Select2Module,
    GalleryModule,
    LightboxModule,
    TusubcategoryModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyB9DMDX78ll840bMxjGL4WUfjAYXfi8vZo'
      apiKey: 'MThkYTY0OGE1OTAyNGYyYmE5M2UyZTA3MzE5YjE3NjQ'
    }),
    Ng2FileInputModule.forRoot(),
    MatDialogModule,
    FileManagerModule,
  ],
  providers: [
    HelperService,
    DataHelperService,
    FilterHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
