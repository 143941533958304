import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expcomp',
  templateUrl: './expcomp.component.html',
  styleUrls: ['./expcomp.component.css']
})
export class ExpcompComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
