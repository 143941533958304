import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HelperService } from './service/service';
import { AccessComponent } from './auth/access/access.component';
import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { RegisterComponent } from './auth/onboarding/register/register.component';
import { RegisterStoreComponent } from './auth/onboarding/registerstore/registerstore.component';
import { RegisterRewardComponent } from './auth/onboarding/registerreward/registerreward.component';
import { RegisterCompleteComponent } from './auth/onboarding/registercomplete/registercomplete.component';
import { ChangePasswordComponent } from './auth/verifybulkmerchants/changepassword/changepassword.component';
import { VerifyNumberComponent } from './auth/verifybulkmerchants/verifynumber/verifynumber.component';
import { VerifyPinComponent } from './auth/verifybulkmerchants/verifypin/verifypin.component';
import { ExpcompComponent } from './expcomp/expcomp.component';
import { MerchantguardGuard } from './service/guard/merchantguard.guard';
import { LoginRequestComponent } from './auth/loginrequest/loginrequest.component';
import { LoginConfirmComponent } from './auth/loginconfirm/loginconfirm.component';
import { TUNotFoundComponent } from './panel/accounts/tunotfound/tunotfound.component';
import { TuNotAccessComponent } from './panel/accounts/tunotaccess/tunotaccess.component';
// import { TUNotFoundComponent } from './panel/accounts/tunotfound/tunotfound.component';

//import { TUAllPlansComponent } from './modules/accounts/tuconsole/tuallplans/tuallplans.component';

const _Routes: Routes = [
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'login', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'forgotpassword', redirectTo: 'account/forgotpassword', pathMatch: 'full' },
  { path: 'resetpassword/:referencekey', redirectTo: 'account/resetpassword', pathMatch: 'full' },
  { path: 'access/:referencekey/:username', component: AccessComponent },
  { path: 'login', component: LoginComponent },
  { path: 'system/notfound', component: TUNotFoundComponent },
  { path: 'system/notaccess', component: TuNotAccessComponent },
  { path: 'account/login', component: LoginRequestComponent },
  { path: 'account/loginconfirm', component: LoginConfirmComponent },
  { path: 'account/register', component: RegisterComponent },
  { path: 'account/setup', component: RegisterStoreComponent },
  { path: 'account/setupcomplete', component: RegisterCompleteComponent },
  { path: 'account/reward', component: RegisterRewardComponent },
  { path: 'account/changepassword', component: ChangePasswordComponent },
  { path: 'account/verifynumber', component: VerifyNumberComponent },
  { path: 'account/verifypin', component: VerifyPinComponent },
  { path: 'account/forgotpassword', component: ForgotPasswordComponent },
  { path: 'account/resetpassword/:referencekey', component: ResetPasswordComponent },
  { path: 'resetpassword/:referencekey', component: ResetPasswordComponent },
  //{ path: 'account/allplans', component: TUAllPlansComponent},
  { canActivate: [HelperService], path: '', loadChildren: './panels/console/console.module#TUConsoleModule' },
 //  { canActivate   : [MerchantguardGuard], path: '', loadChildren: './panels/console/console.module#TUConsoleModule' }

];

@NgModule({
  imports: [RouterModule.forRoot(_Routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
