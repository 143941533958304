import { Component, ViewChild, HostListener, ElementRef, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewFolderDialogComponent } from './modals/new-folder-dialog/new-folder-dialog.component';
import * as Feather from 'feather-icons';
import { ImageEditorComponent } from './modals/image-editor/image-editor.component';
import { GalleryService } from '../../service/gallery.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HelperService } from 'src/app/service/helper.service';
import { RenameImageComponent } from './modals/rename-image/rename-image.component';
import { MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent {
  constructor(
    public dialog: MatDialog,
    private gs: GalleryService,
    private dialogRef: MatDialogRef<FileManagerComponent>,
    private _HelperService: HelperService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  folderData
  fileData
  base64img
  merchantId: string = ""
  offset = 0;
  limit = 100;
  folderArr = []
  fileArr = []
  fileArrCopy = []
  currentFolderElem: any
  private getFolderSubscriber: Subscription;

  pathHistory: string[] = []
  currentPath: string
  folderRef: string = ''
  isLast: boolean = false
  isSidebaropen: boolean = true
  isSidebarVisible: boolean = false
  isSelected: boolean = false;
  selectedImg: any
  elemRef = ''

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsData = []
  searchTerm
  @ViewChild('toggleSelect') toggleSelect: ElementRef;
  isLoading = false;
  displayErrormsg: boolean = false
  SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.toggle()
  }

  ngOnInit() {
    this.merchantId = this.dialogData.merchantId
    this.getFolders()
    this.getFiles(this.merchantId, this.folderRef, this.offset, this.limit)
    this.toggle();
  }

  openSideBar() {
    this.isSidebarVisible = !this.isSidebarVisible
  }

  toggle() {
    this.isSidebaropen = window.innerWidth > 991
  }

  //call getFolder api
  getFolders(path = "") {
    this.isLoading = true
    if (this.getFolderSubscriber) {
      this.getFolderSubscriber.unsubscribe()
    }
    this.getFolderSubscriber = this.gs.getfolders(this.merchantId, this.offset, this.limit).subscribe(res => {
      this.isLoading = false
      this.folderData = res
      this.filterFolderData(path)
    })
  }

  getFiles(merchantId, folderref, offset, limit) {
    this.isLoading = true
    this.gs.getFiles(merchantId, folderref, offset, limit).subscribe(res => {
      this.isLoading = false
      this.fileData = res
      this.fileArr = []
      if (this.fileData.result) {
        this.isLast = this.fileData.result.total < this.limit ? true : false
        this.fileData.result.result.map(el => {
          let ext = el.path.split(".")[1]
          if (ext == "png" || ext == "jpeg" || ext == "jpg") {
            el.name = el.name + "." + el.path.split(".")[1]
            this.fileArr.push(el)
          }
        })
      }
      this.fileArrCopy = this.fileArr
    })
  }

  filterFolderData(path = "") {
    if (this.pathHistory.indexOf(path) === -1) {
      this.pathHistory.push(path);
    }
    this.currentPath = path
    this.currentFolderElem = this.folderData.result.result.filter(e => e.path.split(this.merchantId + "/").pop() == this.currentPath)

    if (this.folderData.result) {
      this.folderArr = []
      this.folderData.result.result.map(el => {
        el.path = el.path.split(this.merchantId + "/").pop()
        if (!path && el.path.split('/')[0] == el.name) {
          this.folderArr.push(el)
        }
        if (path && el.path.includes(path) && el.path !== path && el.path.replace(path, '').split('/').length == 2) {
          this.folderArr.push(el)
        }
      })
    }
  }

  formatBytes(bytes, decimals) {
    for (var i = 0, r = bytes, b = 1000; r > b; i++) r /= b;
    return `${parseFloat(r.toFixed(decimals))} ${this.SIZES[i]}`;
  }

  // getImageSizeInBytes(imgURL) {
  //   var request = new XMLHttpRequest();
  //   request.open("HEAD", imgURL.replace("https://s3.eu-west-2.amazonaws.com/",""), false);
  //   request.send(null);
  //   var headerText = request.getAllResponseHeaders();
  //   var re = /Content\-Length\s*:\s*(\d+)/i;
  //   re.exec(headerText);
  //   return parseInt(RegExp.$1);
  // }

  selectedImage(el) {
    this.isSelected = true
    this.selectedImg = el
    this.elemRef = el.reference
    this.selectedImg["type"] = el.path.split(".")[1]

    // let imageSize = this.getImageSizeInBytes(this.selectedImg.url);
    this.selectedImg.size = el.size > 0 ? this.formatBytes(el.size, 0): "-"

    let img = new Image();
    img.src = this.selectedImg.url;
    img.onload = (event) => {
      let loadedImage: any = event.currentTarget;
      let width = loadedImage.width;
      let height = loadedImage.height;
      this.selectedImg.resolution = el.width? el.width + "x" + el.height: width +"x" + height
      // console.log("height: ", height, "width: ", width);
      if (width != this._HelperService.AppConfig.DealBannerWidth || height != this._HelperService.AppConfig.DealBannerHeight) {
        this.displayErrormsg = true
      } else {
        this.displayErrormsg = false
      }
    }
  }

  choosenImage() {
    this.dialogRef.close({ data: this.selectedImg })
  }

  navigateUp() {
    this.filterFolderData(this.pathHistory[this.pathHistory.length - 2])
    this.pathHistory.pop()
    this.searchTerm = ''
    this.fileArr = []
    this.isSelected = false
    this.folderRef = this.currentFolderElem.length > 0 ? this.currentFolderElem[0].reference : ''
    this.getFiles(this.merchantId, this.folderRef, this.offset, this.limit)
  }

  navigateDown(element) {
    this.fileArr = []
    this.searchTerm = ''
    this.isSelected = false
    this.folderRef = element.reference
    this.filterFolderData(element.path.split(this.merchantId + "/").pop())
    this.getFiles(this.merchantId, element.reference, this.offset, this.limit)
  }

  deleteFolder(e) {
    Swal.fire({
      title: 'Are you sure want to delete ' + e.name + '?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.gs.deleteFolder(this.merchantId, e.reference).subscribe(res => {
          this.getFolders(this.currentPath)
        })
      }
    })
  }

  deleteFile(e) {
    Swal.fire({
      title: 'Are you sure want to delete ' + e.name + '?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.gs.deleteFile(this.merchantId, e.reference).subscribe(res => {
          this.isSelected = false
          this.folderRef = this.currentFolderElem.length > 0 ? this.currentFolderElem[0].reference : ''
          this.getFiles(this.merchantId, this.folderRef, this.offset, this.limit)
        })
      }
    })
  }

  downloadFile(e) {
    window.open(e.url);
  }

  openNewFolderDialog() {
    if (this.currentPath.split('/').length - 1 <= this._HelperService.AppConfig.FolderNestingLevel) {
      let dialogRef = this.dialog.open(NewFolderDialogComponent, {
        data: {
          path: this.currentPath,
          merchantId: this.merchantId
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.getFolders(this.currentPath)
        }
      });
    }
    else {
      this._HelperService.NotifyError("Folder creation is not allowed");
    }
  }

  OpenImageEditorDialog() {
    let dialogRef = this.dialog.open(ImageEditorComponent, {
      width: '1200px',
      height: '750px',
      maxWidth: '1200px',
      maxHeight: '800px',
      disableClose: true,
      data: {
        folderref: this.folderRef,
        imageURL: '',
        merchantId: this.merchantId
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderRef = this.currentFolderElem.length > 0 ? this.currentFolderElem[0].reference : ''
        this.getFiles(this.merchantId, this.folderRef, this.offset, this.limit)
      }
    });
  }

  editImage(url) {
    let dialogRef = this.dialog.open(ImageEditorComponent, {
      width: '1200px',
      height: '750px',
      maxWidth: '1200px',
      maxHeight: '800px',
      disableClose: true,
      data: {
        folderref: this.folderRef,
        imageURL: url,
        merchantId: this.merchantId
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderRef = this.currentFolderElem.length > 0 ? this.currentFolderElem[0].reference : ''
        this.getFiles(this.merchantId, this.folderRef, this.offset, this.limit)
      }
    });
  }

  renameImage(data) {
    let dialogRef = this.dialog.open(RenameImageComponent, {
      data: {
        imgobj: data,
        merchantId: this.merchantId
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderRef = this.currentFolderElem.length > 0 ? this.currentFolderElem[0].reference : ''
        this.getFiles(this.merchantId, this.folderRef, this.offset, this.limit)
      }
    });
  }

  search(value: string): void {
    this.fileArr = this.fileArrCopy
    this.fileArr = this.fileArr.filter((val) =>
      val.name.toLowerCase().includes(value)
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tagsData.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag): void {
    const index = this.tagsData.indexOf(tag);
    if (index >= 0) {
      this.tagsData.splice(index, 1);
    }
  }

  ngAfterViewInit() {
    Feather.replace();
  }
}
