import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../service/service';

@Component({
    selector: 'forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styles: ['.form-control::placeholder {font-size:14px !important}']
})

export class ForgotPasswordComponent implements OnInit {
    _Form_Reset_Processing = false;
    _ResetRequest_Success = false;
    _Form_Reset: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        this._Form_Reset = _FormBuilder.group({
            'username': [null, Validators.required],
        })
    }
    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
    }
    _Form_Reset_Process(value: any) {
        this._Form_Reset_Processing = true;
        var pData = {
            Task: 'resetpassowrd',
            Username: value.username,
            AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Accounts.Manage, pData);
        _OResponse.subscribe(
            _Response => {
                this._Form_Reset_Processing = false;
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    this._HelperService.AppConfig.ActivePasswordResetRequest = _Response.Result;
                    this._HelperService.AppConfig.ActivePasswordResetRequest.Username = value.username;
                    this._ResetRequest_Success = true;
                    this._Form_Reset.reset();
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._Form_Reset_Processing = false;
                this._HelperService.HandleException(_Error);
            });
    }

    NavigateToLogin(): void {
        this._Router.navigate([this._HelperService.AppConfig.Pages.System.Login]);
    }
}
