import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";
import { Select2Module } from "ng2-select2";
import { NgxPaginationModule } from "ngx-pagination";
import { Daterangepicker } from "ng2-daterangepicker";
import { Ng2FileInputModule } from "ng2-file-input";
import { MainPipe } from '../../../service/main-pipe.module'
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputFileConfig, InputFileModule } from 'ngx-input-file';

import { TusubcategoriesComponent } from './tusubcategories.component';

const routes: Routes = [{ path: "", component: TusubcategoriesComponent }];

const config: InputFileConfig = {
  fileAccept: '*',
  fileLimit: 1,
};


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class TUSubCategoriesRoutingModule {}

@NgModule({
  imports: [
     CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    Select2Module,
    NgxPaginationModule,
    Daterangepicker,
    TUSubCategoriesRoutingModule,
    Ng2FileInputModule,
    MainPipe,
    ImageCropperModule,
    InputFileModule.forRoot(config)
  ],
    declarations: [TusubcategoriesComponent]
})
export class TusubcategoryModule { }
