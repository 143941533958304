import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import {
  HttpClientModule,
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
@Component({
  selector: 'registerreward',
  templateUrl: './registerreward.component.html',
})
export class RegisterRewardComponent implements OnInit {

  public PhoneNumber
  public StoreData;
  public IsMobileNumber1 = true;
  public MobileNumber1 = null;
  public MobileNumber1Message = null;
  public MobileNumber2 = null;
  public MobileNumber3 = null;
  Form_AddUser_Processing = false;
  public AccessCode = false;
  Form_AddUser: FormGroup;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  constructor(
    private _Http: HttpClient,
    public _FormBuilder: FormBuilder,
    public _TranslateService: TranslateService,
    public _Router: Router,
    public _HelperService: HelperService,
  ) {

  }
  ngOnInit() {
    this.PhoneNumber = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.VerifyNumber)
    this.StoreData = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.StoreRegistrationData);
  }

  otpValue: number;
  onOtpChange(otp) {
    this.otpValue = otp;
  }
  Otp: string;

  public VerifyCode(): void {
    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchantverifynumber",
      ReferenceKey: this.StoreData.ReferenceKey,
      ReferenceId: this.StoreData.ReferenceId,
      Token: this.StoreData.MerchantToken,
      AccessCode: this.otpValue,
    };
    let _OResponse: Observable<OResponse>;

    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          window.location.href = this._HelperService.AppConfig.Pages.System.MerchantSetupComplete;

        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }
  public ResendCode(): void {
    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchantrequestverification",
      ReferenceKey: this._HelperService.MerchantReferenceKey,
      ReferenceId: this._HelperService.MerchantReferenceId,
      Token: this._HelperService.MerchantToken,
      // Token:this.ngOtpInputRef.getValue(),
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.IsFormProcessing = false;

        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }




}
