import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../service/service';

@Component({
    selector: 'loginconfirm',
    templateUrl: './loginconfirm.component.html',
})
export class LoginConfirmComponent implements OnInit {
    ShowPassword: boolean = true;
    _Form_Login_Processing = false;
    _Form_Login: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        this._Form_Login = _FormBuilder.group({
            'password': ['', Validators.required],
        });

    }
    _Token =
        {
            EmailAddress: null,
            AccessKey: null,
        }
    ngOnInit() {
        var Str = this._HelperService.GetStorage("acctemp");
        if (Str != null) {
            this._Token.AccessKey = Str.RequestKey;
            this._Token.EmailAddress = Str.EmailAddress;
        }
        else {
            this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
            this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
            this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
            this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
            this._HelperService.SetNetwork();
            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.LoginRequest;
        }
    }
    _Form_Login_Process(value: any) {
        this._Form_Login_Processing = true;
        var pData = {
            Task: 'sysloginconfirm',
            Code: value.password,
            AccessKey: this._Token.AccessKey,
            PlatformCode: 'web',
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
        _OResponse.subscribe(
            _Response => {
                this._Form_Login_Processing = false;
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    this._HelperService.LoginCheckVar = false;
                    this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);

                    window.localStorage.setItem("iconimage",_Response.Result.UserAccount.IconUrl);
                    // console.log(_Response.Result.UserAccount.IconUrl);
                    this._HelperService.profileImage=_Response.Result.UserAccount.IconUrl;
                  


                    var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                    _StorageReqH.hcuak = _Response.Result['AccessKey'];
                    _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                    this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                    this._HelperService.NotifySuccess(_Response.Message);
                    this._Form_Login.reset();
                    if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Admin) {
                        var Storage = this._HelperService.GetStorage("temploc");
                        if (Storage != null) {
                            if (Storage.StorageRoute != undefined) {
                                window.location.href = Storage.StorageRoute;
                            }
                            else {
                                window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
                            }
                        }
                        else {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
                        }
                    }
                    else {
                        this._HelperService.NotifyError('Invalid account. Please contact Support');
                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._Form_Login_Processing = false;
                this._HelperService.HandleException(_Error);
            });
    }


    ToogleShowHidePassword(): void {
        this.ShowPassword = !this.ShowPassword;
    }
}
