import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        this.Form_AddUser = _FormBuilder.group({
            OperationType: 'new',
            Task: this._HelperService.AppConfig.Api.Core.onboardmerchant,
            AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant,
            AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
            
            Password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    Validators.pattern(
                        this._HelperService.AppConfig.ValidatorRegex.Password
                    )
                ])
            ],
            // DisplayName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(25)])],
            Name: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
                EmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            MobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(14)])],
    
        })
    }

    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
    }


    resolved(Item) {
        this.capt = Item;
    }
    public capt = undefined;
    Form_AddUser_Process(_FormValue: any) {
       
        _FormValue.DisplayName = _FormValue.Name;
        this.Form_AddUser_Processing = true;
     
        this._HelperService.SaveStorage(
            this._HelperService.AppConfig.Storage.VerifyNumber, cloneDeep(_FormValue.MobileNumber)

        );
   

        this._HelperService.OnBoardMerchant = _FormValue
        this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.MerchantSetup,

        ]);
        
    }


}
