import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../service/service';

@Component({
    selector: 'resetpassword',
    templateUrl: './resetpassword.component.html',
})
export class ResetPasswordComponent implements OnInit {
    _Form_Login_Processing = false;
    _Show_Form_ResetPassword = false;
    _Form_Login: FormGroup;
    _Form_Otp: FormGroup;

    URLData: any = {
        ActiveReferenceKey: null,
        ActiveReferenceId: null,
        Code: null
    };

    constructor(
        public _ActivatedRoute: ActivatedRoute,
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        this._Form_Login = _FormBuilder.group({
            'password': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this._HelperService.AppConfig.ValidatorRegex.Password)])],
            'newpassword': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this._HelperService.AppConfig.ValidatorRegex.Password)])],
        });
        this._Form_Otp = _FormBuilder.group({
            'otp': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])]
        });

        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
        this._ActivatedRoute.params.subscribe((params: Params) => {

            this.URLData.ActiveReferenceKey = params['referencekey'];
            this.URLData.ActiveReferenceId = params['referenceid'];
            this.URLData.Code = params['code'];

        });
    }
    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
    }
    _Form_Login_Process(value: any) {
        if (value.password != value.newpassword) {
            this._HelperService.NotifyError('Password does not match. Both passwords must be same to reset your password');
        }
        else {
            this._Form_Login_Processing = true;
            var pData = {
                Task: 'resetpassowrdconfirm',
                RequestId: this.URLData.ActiveReferenceId,
                RequestKey: this.URLData.ActiveReferenceKey,
                AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant,
                ResetCode: this.URLData.Code,
                NewPassword: value.password
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Accounts.Manage, pData);
            _OResponse.subscribe(
                _Response => {
                    this._Form_Login_Processing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.NotifySuccess('Passsword changed successfully');
                        setTimeout(() => {
                            window.location.href = this._HelperService.AppConfig.Pages.System.Login;
                        }, 1500);
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this._Form_Login_Processing = false;
                    this._HelperService.HandleException(_Error);
                });
        }

    }

    _Verify_Otp(value: any) { }
}
