import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import * as Feather from "feather-icons";
import swal from "sweetalert2";
declare var $: any;

import {
  DataHelperService,
  HelperService,
  OList,
  OSelect,
  FilterHelperService,
} from "../../../service/service";
import { Subscription } from 'rxjs';

@Component({
  selector: "tu-tunotfound",
  templateUrl: "./tunotfound.component.html"
})
export class TUNotFoundComponent implements OnInit, OnDestroy {
  public ResetFilterControls: boolean = true;

  public _ObjectSubscription: Subscription = null;
  constructor(
    public _Router: Router,
    public _ActivatedRoute: ActivatedRoute,
    public _FormBuilder: FormBuilder,
    public _HelperService: HelperService,
    public _DataHelperService: DataHelperService,
    public _ChangeDetectorRef: ChangeDetectorRef,
    public _FilterHelperService: FilterHelperService
  ) {
    this._HelperService.showAddNewPosBtn = false;
    this._HelperService.showAddNewStoreBtn = true;
    this._HelperService.showAddNewCashierBtn = false;
    this._HelperService.showAddNewSubAccBtn = false;

  }


  ngOnInit() {
    // this._HelperService.StopClickPropogation();
    Feather.replace();



    // this._ActivatedRoute.params.subscribe((params: Params) => {

    //   this._HelperService.AppConfig.ActiveMerchantReferenceKey = params['referencekey'];
    //   this._HelperService.AppConfig.ActiveMerchantReferenceId = params['referenceid'];

    //   this.StoresList_Setup();
    //   this.StoresList_Filter_Owners_Load();
    //   this.InitColConfig();
      

    // });

    // this._ObjectSubscription = this._HelperService.ObjectCreated.subscribe(value => {
    //   this.StoresList_GetData();
    // });
  }

  ngOnDestroy(): void {
    try {
      this._ObjectSubscription.unsubscribe();
    } catch (error) {
    }
  }

  Form_AddUser_Open() {
    this._Router.navigate([
      this._HelperService.AppConfig.Pages.ThankUCash.PanelConsole
        .MerchantOnboarding,
    ]);
  }

  //#region columnConfig

  TempColumnConfig: any = [
    {
      Name: "Status",
      Value: true,
    },
    {
      Name: "City",
      Value: true,
    },
    {
      Name: "Contact",
      Value: true,
    },
    {
      Name: "Stores",
      Value: true,
    },
    {
      Name: "Reward",
      Value: true,
    },
    {
      Name: "POS",
      Value: true,
    },
    {
      Name: "ActivePOS",
      Value: true,
    },
    {
      Name: "RM",
      Value: true,
    },
    {
      Name: "Added",
      Value: true,
    },
  ];

  ColumnConfig: any = [
    {
      Name: "Status",
      Value: true,
    },
    {
      Name: "City",
      Value: true,
    },
    {
      Name: "Contact",
      Value: true,
    },
    {
      Name: "Stores",
      Value: true,
    },
    {
      Name: "Reward",
      Value: true,
    },
    {
      Name: "POS",
      Value: true,
    },
    {
      Name: "ActivePOS",
      Value: true,
    },
    {
      Name: "RM",
      Value: true,
    },
    {
      Name: "Added",
      Value: true,
    },
  ];

  InitColConfig() {
    var MerchantTableConfig = this._HelperService.GetStorage("BMerchantTable");
    var ColConfigExist: boolean =
      MerchantTableConfig != undefined && MerchantTableConfig != null;
    if (ColConfigExist) {
      this.ColumnConfig = MerchantTableConfig.config;
      this.TempColumnConfig = this._HelperService.CloneJson(
        MerchantTableConfig.config
      );
    }
  }

  OpenEditColModal() {
    this._HelperService.OpenModal("EditCol");
  }

  SaveEditCol() {
    this.ColumnConfig = this._HelperService.CloneJson(this.TempColumnConfig);
    this._HelperService.SaveStorage("BMerchantTable", {
      config: this.ColumnConfig,
    });
    this._HelperService.CloseModal("EditCol");
  }

  HomePage(){
    // this._Router.navigate([
    //   this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant,
   
    // ]);

     this._Router.navigate([
      this._HelperService.AppConfig.Pages.System.Login,
   
    ]);
    
  }
  //#endregion

  //#region Storelist

  public StoresList_Config: OList;
  StoresList_Setup() {
    this.StoresList_Config = {
      Id: null,
      // Sort: null,
      Task: this._HelperService.AppConfig.Api.ThankUCash.GetStores,
      Location: this._HelperService.AppConfig.NetworkLocation.Console.V3.Accounts,
      Title: "Available Stores",
      StatusType: "default",
      // Type: this._HelperService.AppConfig.ListType.SubOwner,
      // AccountKey: this._HelperService.UserAccount.AccountKey,
      // AccountId: this._HelperService.UserAccount.AccountId,
      DefaultSortExpression: "CreateDate desc",
      IsDownload: true,
      Sort:
      {
          SortDefaultName: null,
          SortDefaultColumn: 'CreateDate',
          SortName: null,
          SortColumn: '',
          SortOrder: 'desc',
          SortOptions: [],
      },
      // SearchBaseCondition: this._HelperService.GetSearchConditionStrict('', 'StatusId', this._HelperService.AppConfig.DataType.Number, 2, '=='),
      TableFields: [
        {
          DisplayName: " Name",
          SystemName: "DisplayName",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "",
          Show: true,
          Search: true,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },
        {
          DisplayName: "Contact No",
          SystemName: "ContactNumber",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "text-center",
          Show: true,
          Search: true,
          Sort: false,
          ResourceId: null,
          DefaultValue: "ThankUCash",
          NavigateField: "ReferenceKey"
        },
        {
          DisplayName: "Email Address",
          SystemName: "EmailAddress",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "",
          Show: true,
          Search: true,
          Sort: false,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },

        {
          DisplayName: "Total Terminals",
          SystemName: "TotalTerminals",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "text-right",
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },
        {
          DisplayName: "Active Terminals",
          SystemName: "ActiveTerminals",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "text-right",
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },
        {
          DisplayName: "Cashiers",
          SystemName: "Cashiers",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "text-right",
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        }, {
          DisplayName: "RM",
          SystemName: "RmDisplayName",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "text-right",
          Show: true,
          Search: false,
          Sort: false,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },

        {
          DisplayName: this._HelperService.AppConfig.CommonResource.CreateDate,
          SystemName: "CreateDate",
          DataType: this._HelperService.AppConfig.DataType.Date,
          Class: "td-date text-right",
          Show: true,
          IsDateSearchField: true,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey",
        },
      ]
    };
    this.StoresList_Config = this._DataHelperService.List_Initialize(
      this.StoresList_Config
    );

    this._HelperService.Active_FilterInit(
      this._HelperService.AppConfig.FilterTypeOption.Stores,
      this.StoresList_Config
    );

    this.StoresList_GetData();
  }
  StoresList_ToggleOption(event: any, Type: any) {

    if (event != null) {
      for (let index = 0; index < this.StoresList_Config.Sort.SortOptions.length; index++) {
        const element = this.StoresList_Config.Sort.SortOptions[index];
        if (event.SystemName == element.SystemName) {
          element.SystemActive = true;
        }
        else {
          element.SystemActive = false;
        }
      }
    }

    this._HelperService.Update_CurrentFilterSnap(
      event,
      Type,
      this.StoresList_Config
    );

    this.StoresList_Config = this._DataHelperService.List_Operations(
      this.StoresList_Config,
      event,
      Type
    );

    if (
      (this.StoresList_Config.RefreshData == true)
      && this._HelperService.DataReloadEligibility(Type)
    ) {
      this.StoresList_GetData();
    }

  }

  timeout = null;
  StoresList_ToggleOptionSearch(event: any, Type: any) {

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (event != null) {
        for (let index = 0; index < this.StoresList_Config.Sort.SortOptions.length; index++) {
          const element = this.StoresList_Config.Sort.SortOptions[index];
          if (event.SystemName == element.SystemName) {
            element.SystemActive = true;
          }
          else {
            element.SystemActive = false;
          }
        }
      }

      this._HelperService.Update_CurrentFilterSnap(
        event,
        Type,
        this.StoresList_Config
      );

      this.StoresList_Config = this._DataHelperService.List_Operations(
        this.StoresList_Config,
        event,
        Type
      );

      if (
        (this.StoresList_Config.RefreshData == true)
        && this._HelperService.DataReloadEligibility(Type)
      ) {
        this.StoresList_GetData();
      }
    }, this._HelperService.AppConfig.SearchInputDelay);


  }

  StoresList_GetData() {
    var TConfig = this._DataHelperService.List_GetData(
      this.StoresList_Config
    );
    this.StoresList_Config = TConfig;
  }
  StoresList_RowSelected(ReferenceData) {
    this._HelperService.SaveStorage(
      this._HelperService.AppConfig.Storage.ActiveStore,
      {
        ReferenceKey: ReferenceData.ReferenceKey,
        ReferenceId: ReferenceData.ReferenceId,
        MerchantId:ReferenceData.MerchantReferenceId,
        MerchantKey: ReferenceData.MerchantReferenceKey,
        DisplayName: ReferenceData.DisplayName,
        AccountTypeCode: this._HelperService.AppConfig.AccountType.Store,
      }
    );

    this._HelperService.AppConfig.ActiveStoreReferenceKey =
      ReferenceData.ReferenceKey;
    this._HelperService.AppConfig.ActiveStoreReferenceId = ReferenceData.ReferenceId;
    this._HelperService.AppConfig.ActiveMerchantReferenceKey =
      ReferenceData.MerchantReferenceKey;
    this._HelperService.AppConfig.ActiveMerchantReferenceId = ReferenceData.MerchantReferenceId;
    // this._Router.navigate([
    //   this._HelperService.AppConfig.Pages.ThankUCash.PanelConsole.Store
    //     .Dashboard,
    //   ReferenceData.ReferenceKey,
    //   ReferenceData.ReferenceId,
    // ]);

    this._Router.navigate([
      this._HelperService.AppConfig.Pages.ThankUCash.PanelConsole.Store.SalesHistory,
      ReferenceData.ReferenceKey,
      ReferenceData.ReferenceId,
      ReferenceData.MerchantReferenceKey,
      ReferenceData.MerchantReferenceId
    ]);


  }

  //#endregion

  //#region OwnerFilter

  public StoresList_Filter_Owners_Option: Select2Options;
  public StoresList_Filter_Owners_Selected = null;
  StoresList_Filter_Owners_Load() {
    var PlaceHolder = this._HelperService.AppConfig.CommonResource.Select;
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.ThankUCash.GetMerchants,
      Location: this._HelperService.AppConfig.NetworkLocation.V2.TUCAccCore,
      // ReferenceKey: this._HelperService.UserAccount.AccountKey,
      // ReferenceId: this._HelperService.UserAccount.AccountId,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceId",
          Type: this._HelperService.AppConfig.DataType.Number,
          Id: true,
          Text: false,
        },
        {
          SystemName: "DisplayName",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true,
        },
      ],
    };
    // _Select.SearchCondition = this._HelperService.GetSearchConditionStrictFromArray('', 'AccountTypeCode', this._HelperService.AppConfig.DataType.Text,
    //   [
    //     this._HelperService.AppConfig.AccountType.Merchant,
    //     this._HelperService.AppConfig.AccountType.Acquirer,
    //     this._HelperService.AppConfig.AccountType.PGAccount,
    //     this._HelperService.AppConfig.AccountType.PosAccount
    //   ]
    //   , '=');
    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    this.StoresList_Filter_Owners_Option = {
      placeholder: "Sort by Referrer",
      ajax: _Transport,
      multiple: false,
      allowClear: true,
    };
  }
  StoresList_Filter_Owners_Change(event: any) {
    this._HelperService.Update_CurrentFilterSnap(
      event,
      this._HelperService.AppConfig.ListToggleOption.Other,
      this.StoresList_Config,
      this._HelperService.AppConfig.OtherFilters.Merchant.Owner
    );

    this.OwnerEventProcessing(event);

  }

  OwnerEventProcessing(event: any): void {
    if (event.value == this.StoresList_Filter_Owners_Selected) {
      var SearchCase = this._HelperService.GetSearchConditionStrict(
        "",
        "MerchantReferenceKey",
        this._HelperService.AppConfig.DataType.Text,
        this.StoresList_Filter_Owners_Selected,
        "="
      );
      this.StoresList_Config.SearchBaseConditions = this._HelperService.RemoveItemFromArray(
        SearchCase,
        this.StoresList_Config.SearchBaseConditions
      );
      this.StoresList_Filter_Owners_Selected = null;
    } else if (event.value != this.StoresList_Filter_Owners_Selected) {
      var SearchCase = this._HelperService.GetSearchConditionStrict(
        "",
        "MerchantReferenceKey",
        this._HelperService.AppConfig.DataType.Text,
        this.StoresList_Filter_Owners_Selected,
        "="
      );
      this.StoresList_Config.SearchBaseConditions = this._HelperService.RemoveItemFromArray(
        SearchCase,
        this.StoresList_Config.SearchBaseConditions
      );
      this.StoresList_Filter_Owners_Selected = event.data[0].ReferenceKey;
      this.StoresList_Config.SearchBaseConditions.push(
        this._HelperService.GetSearchConditionStrict(
          "",
          "MerchantReferenceKey",
          this._HelperService.AppConfig.DataType.Text,
          this.StoresList_Filter_Owners_Selected,
          "="
        )
      );
    }

    this.StoresList_ToggleOption(
      null,
      this._HelperService.AppConfig.ListToggleOption.ResetOffset
    );
  }

  //#endregion

  SetOtherFilters(): void {
    this.StoresList_Config.SearchBaseConditions = [];
    this.StoresList_Config.SearchBaseCondition = null;

    var CurrentIndex = this._HelperService.FilterSnap.OtherFilters.findIndex((filter) => (filter.data[0].OtherType == this._HelperService.AppConfig.OtherFilters.Merchant.Owner));
    if (CurrentIndex != -1) {
      this.StoresList_Filter_Owners_Selected = null;
      this.OwnerEventProcessing(this._HelperService.FilterSnap.OtherFilters[CurrentIndex]);
    }
  }

  //#region filterOperations

  Active_FilterValueChanged(event: any) {
    this._HelperService.Active_FilterValueChanged(event);
    this._FilterHelperService.SetMerchantConfig(this.StoresList_Config);

    //#region setOtherFilters
    this.SetOtherFilters();
    //#endregion

    this.StoresList_GetData();
  }

  RemoveFilterComponent(Type: string, index?: number): void {
    this._FilterHelperService._RemoveFilter_Store(Type, index);
    this._FilterHelperService.SetMerchantConfig(this.StoresList_Config);

    this.SetOtherFilters();

    this.StoresList_GetData();
  }

  Save_NewFilter() {
    swal({
      position: "center",
      title: this._HelperService.AppConfig.CommonResource.SaveFilterTitle,
      text: this._HelperService.AppConfig.CommonResource.SaveFilterHelp,
      // input: "text",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="filter name" class="swal2-input">' +
        '<label class="mg-x-5 mg-t-5">Private</label><input type="radio" checked name="swal-input2" id="swal-input2" class="">' +
        '<label class="mg-x-5 mg-t-5">Public</label><input type="radio" name="swal-input2" id="swal-input3" class="">',
      focusConfirm: false,
      preConfirm: () => {
        return {
          filter: document.getElementById('swal-input1')['value'],
          private: document.getElementById('swal-input2')['checked']
        }
      },
      // inputPlaceholder: this._HelperService.AppConfig.CommonResource.FilterName,
      // inputAttributes: {
      //   autocapitalize: "off",
      //   autocorrect: "off",
      //   maxLength: "4",
      //   minLength: "4",
      // },
      animation: false,
      customClass: this._HelperService.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this._HelperService.AppConfig.Color_Green,
      cancelButtonColor: this._HelperService.AppConfig.Color_Grey,
      confirmButtonText: "Save",
      cancelButtonText: this._HelperService.AppConfig.CommonResource.Cancel,
    }).then((result) => {
      if (result.value) {

        if (result.value.filter.length < 5) {
          this._HelperService.NotifyError('Enter filter name length greater than 4');
          return;
        }

        this._HelperService._RefreshUI = false;
        this._ChangeDetectorRef.detectChanges();

        this._FilterHelperService._BuildFilterName_Merchant(result.value.filter);

        var AccessType: number = result.value.private ? 0 : 1;
        this._HelperService.Save_NewFilter(
          this._HelperService.AppConfig.FilterTypeOption.Stores,
          AccessType
        );

        this._HelperService._RefreshUI = true;
        this._ChangeDetectorRef.detectChanges();
      }
    });
  }

  Delete_Filter() {

    swal({
      position: "center",
      title: this._HelperService.AppConfig.CommonResource.DeleteTitle,
      text: this._HelperService.AppConfig.CommonResource.DeleteHelp,
      animation: false,
      customClass: this._HelperService.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this._HelperService.AppConfig.Color_Red,
      cancelButtonColor: this._HelperService.AppConfig.Color_Grey,
      confirmButtonText: this._HelperService.AppConfig.CommonResource.Continue,
      cancelButtonText: this._HelperService.AppConfig.CommonResource.Cancel

    }).then((result) => {
      if (result.value) {
        this._HelperService._RefreshUI = false;
        this._ChangeDetectorRef.detectChanges();

        this._HelperService.Delete_Filter(
          this._HelperService.AppConfig.FilterTypeOption.Stores
        );
        this._FilterHelperService.SetMerchantConfig(this.StoresList_Config);
        this.StoresList_GetData();

        this._HelperService._RefreshUI = true;
        this._ChangeDetectorRef.detectChanges();
      }
    });

  }

  ApplyFilters(event: any, Type: any, ButtonType: any): void {
    this._HelperService.MakeFilterSnapPermanent();
    this.StoresList_GetData();

    if (ButtonType == 'Sort') {
      $("#StoresList_sdropdown").dropdown('toggle');
    } else if (ButtonType == 'Other') {
      $("#StoresList_fdropdown").dropdown('toggle');
    }

    this.ResetFilterUI(); this._HelperService.StopClickPropogation();
  }

  ResetFilters(event: any, Type: any): void {
    this._HelperService.ResetFilterSnap();
    this._FilterHelperService.SetMerchantConfig(this.StoresList_Config);
    this.SetOtherFilters();

    this.StoresList_GetData();

    this.ResetFilterUI(); this._HelperService.StopClickPropogation();
  }

  //#endregion

  ResetFilterUI(): void {
    this.ResetFilterControls = false;
    this._ChangeDetectorRef.detectChanges();

    this.StoresList_Filter_Owners_Load();

    this.ResetFilterControls = true;
    this._ChangeDetectorRef.detectChanges();
  }

}
