import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import * as Feather from "feather-icons";
import swal from "sweetalert2";
declare var $: any;

import {
  DataHelperService,
  HelperService,
  OList,
  OSelect,
  FilterHelperService,
} from "../../../service/service";
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-tunotaccess',
  templateUrl: './tunotaccess.component.html',
  styleUrls: ['./tunotaccess.component.css']
})
export class TuNotAccessComponent implements OnInit,OnDestroy {

  public ResetFilterControls: boolean = true;

  public _ObjectSubscription: Subscription = null;
  constructor(
    public _Router: Router,
    public _ActivatedRoute: ActivatedRoute,
    public _FormBuilder: FormBuilder,
    public _HelperService: HelperService,
    public _DataHelperService: DataHelperService,
    public _ChangeDetectorRef: ChangeDetectorRef,
    public _FilterHelperService: FilterHelperService
  ) {
    this._HelperService.showAddNewPosBtn = false;
    this._HelperService.showAddNewStoreBtn = true;
    this._HelperService.showAddNewCashierBtn = false;
    this._HelperService.showAddNewSubAccBtn = false;

  }


  ngOnInit() {
    // this._HelperService.StopClickPropogation();
    Feather.replace();
  }

  ngOnDestroy(): void {
    try {
      this._ObjectSubscription.unsubscribe();
    } catch (error) {
    }
  }
  HomePage(){
    // this._Router.navigate([
    //   this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant,
   
    // ]);

     this._Router.navigate([
      this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console,
   
    ]);
    
  }
}
