import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse, DataHelperService } from '../../service/service';

@Component({
    selector: 'access',
    templateUrl: './access.component.html',
})
export class AccessComponent implements OnInit {
    _Form_Login_Processing = false;
    _Form_Login: FormGroup;
    constructor(
        public _Router: Router,
        public _ActivatedRoute: ActivatedRoute,
        public _FormBuilder: FormBuilder,
        public _HelperService: HelperService,
        public _DataHelperService: DataHelperService,
        public _TranslateService: TranslateService,
    ) {
        this._Form_Login = _FormBuilder.group({
            'username': [null, Validators.required],
            'password': [null, Validators.required],
        })
        this._ActivatedRoute.params.subscribe((params: Params) => {
            var AccountKey = params['referencekey'];
            var UserName = params['username'];
            if (AccountKey != undefined && UserName != undefined) {
                this.ProcessAccount(UserName, AccountKey);
            }
        });
    }
    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
    }
    ProcessAccount(username, password) {
        this._Form_Login_Processing = true;
        var pData = {
            Task: 'login',
            UserName: username,
            AccountKey: password,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
        _OResponse.subscribe(
            _Response => {
                this._Form_Login_Processing = false;
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
                    var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                    _StorageReqH.hcuak = _Response.Result['AccessKey'];
                    _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                    this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                    this._HelperService.NotifySuccess(_Response.Message);
                    this._Form_Login.reset();
                    if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.PosAccount) {
                        window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.PTSP;
                    }
                    else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
                        window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                    }
                    else {
                        this._HelperService.NotifyError('Invalid account. Please contact Support');
                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._Form_Login_Processing = false;
                this._HelperService.HandleException(_Error);
            });
    }
    _Form_Login_Process(value: any) {
        this._Form_Login_Processing = true;
        var pData = {
            Task: 'login',
            UserName: value.username,
            Password: value.password,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
        _OResponse.subscribe(
            _Response => {
                this._Form_Login_Processing = false;
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
                    var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                    _StorageReqH.hcuak = _Response.Result['AccessKey'];
                    _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                    this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                    this._HelperService.NotifySuccess(_Response.Message);
                    this._Form_Login.reset();
                    if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.PosAccount) {
                        window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.PTSP;
                    }
                    else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
                        window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                    }
                    else {
                        this._HelperService.NotifyError('Invalid account. Please contact SUpport');
                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._Form_Login_Processing = false;
                this._HelperService.HandleException(_Error);
            });
    }
}
